import { css } from '@emotion/react'

export const container = css`
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
  padding-top: 55px;
  padding-bottom: 20px;
  margin-bottom: 30px;
`

export const buttonContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`

export const actionButtonContainer = css`
  margin-left: auto;
`

export const actionButton = css`
  margin-right: 10px;
`
