import { createContext } from 'react'

import { IEntityPickerContext, IEntityPickerProviderProps } from './types'

export const EntityPickerContext = createContext<IEntityPickerContext>({
  isEntityPickerModal: false
})

const EntityPickerProvider: React.FC<IEntityPickerProviderProps> = ({
  children,
  isEntityPickerModal
}) => {
  return (
    <EntityPickerContext.Provider value={{ isEntityPickerModal }}>
      {children}
    </EntityPickerContext.Provider>
  )
}

export default EntityPickerProvider
