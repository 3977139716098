import PhatPageContainer from '@components/phat_page_container'

const Custom404 = () => (
  <PhatPageContainer>
    <h1>Page not Found</h1>
    <p>We can’t find the page you’re looking for</p>
  </PhatPageContainer>
)

export default Custom404
