import { SerializedStyles, css } from '@emotion/react'

export const container = (
  isEntityPickerModal: boolean
): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  ${!isEntityPickerModal && 'min-height: calc(100vh - 64px);'}
`
export const main = css`
  flex-grow: 1;
`
