import PropTypes from 'prop-types'

const CustomLink = ({ children, className, style, to }) => {
  return (
    <a
      className={className}
      style={style}
      href={to}
    >
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.any,
  style: PropTypes.any,
  to: PropTypes.string.isRequired
}

CustomLink.defaultProps = {
  className: '',
  style: {}
}

export default CustomLink
