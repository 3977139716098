/* eslint-disable react/prop-types */
/* istanbul ignore file */
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

const Row = ({ children, center = false }) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent={center && 'center'}
    >
      {children}
    </Grid>
  )
}

const Column = ({ mother, brother, sister, baby, children }) => {
  return (
    <Grid
      item
      xs={baby}
      sm={sister}
      lg={brother}
      xl={mother}
    >
      {children}
    </Grid>
  )
}

export { Container, Column, Row }
