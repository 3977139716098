import Typography from '@mui/material/Typography'
import * as React from 'react'

import Modal from '@components/modal'
import { TabbedTitleBar } from '@components/tabbed_title_bar'
import TitleBar from '@components/title_bar'
import { useHistory } from '@hooks/react-router-dom'
import { useIsEntityPickerModal } from '@hooks/use_is_entity_picker_modal'

import * as styles from './styles'
import { IPhatPageContainerProps } from './types'

const PhatPageContainer: React.FC<IPhatPageContainerProps> = ({
  children,
  titleBarProps,
  tabbedTitleBarProps,
  isModal,
  modalTitle
}: IPhatPageContainerProps) => {
  const { isEntityPickerModal } = useIsEntityPickerModal()
  const history = useHistory()

  const handleClose = (): void => {
    history.back()
  }

  const renderTitleBar = (): JSX.Element | null => {
    if (isEntityPickerModal) return null

    if (titleBarProps) return <TitleBar {...titleBarProps} />

    if (tabbedTitleBarProps) return <TabbedTitleBar {...tabbedTitleBarProps} />

    return null
  }

  const renderNonModalPage = (): JSX.Element => {
    return (
      <main css={styles.main}>
        {renderTitleBar()}
        {children}
      </main>
    )
  }

  const renderModalTitle = (): JSX.Element | null => {
    if (!modalTitle) return null

    return (
      <Typography
        variant='h4'
        mt={2}
        align='center'
        gutterBottom={true}
      >
        {modalTitle}
      </Typography>
    )
  }

  const renderModalPage = (): JSX.Element => {
    return (
      <Modal
        display
        closeHandler={handleClose}
        padding={false}
      >
        {renderModalTitle()}
        {children}
      </Modal>
    )
  }

  const renderPage = (): JSX.Element => {
    if (isModal) return renderModalPage()

    return renderNonModalPage()
  }

  return <div css={styles.container(isEntityPickerModal)}>{renderPage()}</div>
}

export default PhatPageContainer
