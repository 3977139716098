import Backdrop from '@mui/material/Backdrop'
import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import React from 'react'

import { MODAL_CLOSE_ICON_TEST_ID } from './constants'
import * as styles from './styles'

const Modal = ({ children, closeHandler, display, padding, width }) => {
  if (!display) return null

  const renderBackdrop = () => {
    return (
      <Backdrop
        data-testid={MODAL_CLOSE_ICON_TEST_ID}
        onClick={closeHandler}
        open={display}
      />
    )
  }

  return (
    <Dialog
      onClose={closeHandler}
      open={display}
      maxWidth={width.toString()}
      slots={{ backdrop: renderBackdrop }}
    >
      <div css={[styles.container(width), padding && styles.padded]}>
        {children}
      </div>
    </Dialog>
  )
}

Modal.defaultProps = {
  width: 615,
  padding: true,
  closeHandler: /* istanbul ignore next */ () => {}
}

Modal.propTypes = {
  width: PropTypes.number,
  display: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func,
  padding: PropTypes.bool,
  children: PropTypes.any.isRequired
}

export default Modal
