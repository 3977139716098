import Add from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React from 'react'

import { Column, Container, Row } from '@components/grids'

import * as styles from './styles'

const TitleBar = ({
  title,
  createButtonClickHandler,
  createButtonText,
  actionButtons,
  hideCreateButton
}) => {
  const renderActionButtons = () => {
    return actionButtons.map((actionButton, i) => (
      <Button
        key={i}
        onClick={actionButton.clickHandler}
        variant='outlined'
        startIcon={actionButton.icon}
        disabled={actionButton.disabled}
        css={styles.actionButton}
      >
        {actionButton.title}
      </Button>
    ))
  }

  const renderCreateButton = () => {
    if (hideCreateButton) return null

    return (
      <Button
        onClick={createButtonClickHandler}
        startIcon={<Add />}
      >
        {createButtonText}
      </Button>
    )
  }

  return (
    <div css={styles.container}>
      <Container>
        <Row center>
          <Column
            mother={11}
            brother={11}
            sister={11}
            baby={11}
          >
            <Typography
              variant='h2'
              component='h1'
              css={styles.title}
            >
              {title}
            </Typography>
            <div css={styles.buttonContainer}>
              {renderCreateButton()}
              <div css={styles.actionButtonContainer}>
                {renderActionButtons()}
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

/* istanbul ignore next */
TitleBar.defaultProps = {
  actionButtons: [],
  createButtonText: 'Create New',
  createButtonClickHandler: () => {},
  hideCreateButton: false
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  createButtonClickHandler: PropTypes.func,
  createButtonText: PropTypes.string,
  hideCreateButton: PropTypes.bool,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      clickHandler: PropTypes.func.isRequired,
      icon: PropTypes.element.isRequired,
      disabled: PropTypes.bool
    })
  )
}

TitleBar.displayName = 'TitleBar'

export default TitleBar
