import PropTypes from 'prop-types'
import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import CustomLink from '@components/custom_link'
import Flag from '@components/flags'
import { Column, Container, Row } from '@components/grids'
import { useHistory } from '@hooks/react-router-dom'

import { routeIsActive } from './helpers'
import * as styles from './styles'

export const TabbedTitleBar = ({ tabs, name, country }) => {
  const history = useHistory()

  const renderTabUnderline = (tabActive) => {
    if (!tabActive) return null

    return <div css={styles.tabUnderline} />
  }

  const renderTabs = () => {
    return tabs.map(({ route, name: tabName }) => {
      const tabActive = routeIsActive(route, history)
      return (
        <CustomLink
          key={`${tabName}_title_tab`}
          css={[styles.tab, tabActive && styles.tabActive]}
          to={route}
        >
          {tabName}
          {renderTabUnderline(tabActive)}
        </CustomLink>
      )
    })
  }

  const renderCountry = () => {
    const { code, defaultLanguage } = country
    const countryCode = code.toLowerCase()
    return (
      <div css={styles.country}>
        <div css={styles.countryIcon}>
          <Flag countryCode={countryCode} />
        </div>
        <span css={styles.countryText}>
          {code} - Lang: ({defaultLanguage})
        </span>
      </div>
    )
  }

  const renderTitle = () => <h1 css={styles.title}>{name}</h1>

  return (
    <div css={styles.base}>
      <Container>
        <Row center>
          <Column
            mother={11}
            brother={11}
            sister={11}
            baby={11}
          >
            {renderCountry()}
            {renderTitle()}
            {renderTabs()}
          </Column>
        </Row>
      </Container>
    </div>
  )
}

TabbedTitleBar.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired
    })
  ).isRequired,
  country: PropTypes.shape({
    code: PropTypes.string.isRequired,
    defaultLanguage: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired
}

export default createFragmentContainer(TabbedTitleBar, {
  country: graphql`
    fragment tabbedTitleBar_country on Country {
      code
      defaultLanguage
    }
  `
})
