import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Fonts from '@thebeansgroup/ui_style_helpers/fonts'

const TAB_UNDERLINE_HEIGHT = '5px'

export const base = css`
  background-color: ${Colours('uiBlue', 500)};
  margin-bottom: 40px;
  padding-top: 56px;
  user-select: none;
`

export const country = css`
  margin-bottom: 16px;
`

export const countryIcon = css`
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
`

export const countryText = css`
  color: ${Colours('white')};
  font-size: 14px;
`

export const title = css`
  color: ${Colours('white')};
  font-size: 48px;
  margin-bottom: 30px;
`

export const tab = css`
  position: relative;
  display: inline-block;
  color: ${Colours('liquorice', '100')};
  opacity: 0.5;
  cursor: pointer;
  margin-right: 28px;
  padding-bottom: 14px;
  font-family: ${Fonts('gibson')};
`

export const tabActive = css`
  color: ${Colours('white')};
  opacity: 1;
`

export const tabUnderline = css`
  position: absolute;
  bottom: -${TAB_UNDERLINE_HEIGHT};
  display: block;
  height: ${TAB_UNDERLINE_HEIGHT};
  width: 100%;
  background-color: ${Colours('uiBlue', 500)};
`
