/**
 * @generated SignedSource<<02857b6b1cc0a668216f475daeb1ad3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type tabbedTitleBar_country$data = {
  readonly code: string | null | undefined;
  readonly defaultLanguage: string | null | undefined;
  readonly " $fragmentType": "tabbedTitleBar_country";
};
export type tabbedTitleBar_country$key = {
  readonly " $data"?: tabbedTitleBar_country$data;
  readonly " $fragmentSpreads": FragmentRefs<"tabbedTitleBar_country">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "tabbedTitleBar_country",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultLanguage",
      "storageKey": null
    }
  ],
  "type": "Country",
  "abstractKey": null
};

(node as any).hash = "24558280264f9dbea25534c1a8ecfa99";

export default node;
